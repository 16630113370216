<template>
  <div>
    <section
      class="container page-section d-flex flex-column align-items-center"
      style=""
    >
      <div class="text-center">
        <h2 class="pl-2 pr-2">
          {{ $t("dicas_covid.title") }}
        </h2>
      </div>
      <div class="row pt-5">
        <!-- class="d-flex justify-content-center text-center col-sm-6 col-md-4" -->
        <div
          class="d-flex justify-content-center text-center col-sm-6 col-md-6"
          v-for="(item, index) in sections"
          :key="index"
        >
          <a :href="`#${item.id}`">
            <b-card
              :title="$t('dicas_covid.' + item.translate_key)"
              class="list__item"
              :img-src="require(`../assets/icons/dicas/${item.img}`)"
              img-alt="Image"
              img-top
              tag="article"
              style="min-width:250px; border-radius: 10px 10px 10px 10px;"
            >
            </b-card>
          </a>
        </div>
      </div>

      <list-dicas
        _id="delivery"
        class="mt-5"
        :data="delivery_1"
        :title="$t('dicas_covid.delivery.title')"
        :subtitle="$t('dicas_covid.delivery.1-0')"
      />
      <list-dicas
        _id="delivery2"
        class="mt-5"
        :data="delivery_2"
        title=""
        :subtitle="$t('dicas_covid.delivery.2-0')"
      />
      <list-dicas
        _id="estabelecimento"
        class="mt-5"
        :data="estabelecimento"
        :title="$t('dicas_covid.delivery.title')"
        subtitle=""
      />
    </section>
  </div>
</template>
<script>
import ListDicas from "../components/ListDicas";
export default {
  components: {
    ListDicas
  },
  data() {
    return {
      sections: [
        {
          id: "delivery",
          translate_key: "card-delivery",
          img: "delivery3_green.svg"
        },
        {
          id: "estabelecimento",
          translate_key: "card-estabelecimento",
          img: "market4_green.svg"
        }
      ],
      delivery_1: [
        {
          translate_key: "delivery.1-1",
          title: "",
          img: "aperto_maos.svg"
        },
        {
          translate_key: "delivery.1-2",
          title: "",
          img: "limpar_pagamentos.svg"
        },
        {
          translate_key: "delivery.1-3",
          title: "",
          img: "use_mascara.svg"
        },
        {
          translate_key: "delivery.1-4",
          title: "",
          img: "produtos_higiene_green.svg"
        }
      ],
      delivery_2: [
        {
          translate_key: "delivery.2-1",
          title: "",
          img: "lavar_maos_green.svg"
        },
        {
          translate_key: "delivery.2-2",
          title: "",
          img: "aperto_maos.svg"
        },
        {
          translate_key: "delivery.2-3",
          title: "",
          img: "nao_mexer_mascara2.svg"
        },
        {
          translate_key: "delivery.2-4",
          title: "",
          img: "lavar_mascara_green.svg"
        },
        {
          translate_key: "delivery.3-0",
          title: "",
          img: "kit.svg"
        }
      ],
      estabelecimento: [
        {
          translate_key: "estabelecimento.1",
          title: "",
          img: "produtos_higiene_green.svg"
        },
        {
          translate_key: "estabelecimento.2",
          title: "",
          img: "use_epi.svg"
        },
        {
          translate_key: "estabelecimento.3",
          title: "",
          img: "tosse_cotovelo_green.svg"
        },
        {
          translate_key: "estabelecimento.4",
          title: "",
          img: "sem_aglomeracao.svg"
        },
        {
          translate_key: "estabelecimento.5",
          title: "",
          img: "lavar_maos_green.svg"
        },
        {
          translate_key: "estabelecimento.6",
          title: "",
          img: "identificar_sintomas.svg"
        },
        {
          translate_key: "estabelecimento.7",
          title: "",
          img: "nap_trabalhar_doente.svg"
        },
        {
          translate_key: "estabelecimento.8",
          title: "",
          img: "nao_trocar_objetos.svg"
        },
        {
          translate_key: "estabelecimento.9",
          title: "",
          img: "shield_covid_green.svg"
        },
        {
          translate_key: "estabelecimento.10",
          title: "",
          img: "colocar_avisos.svg"
        }
      ]
    };
  },
  mounted() {
    this.translateDelivery1();
    this.translateDelivery2();
    this.translateEstabelecimento();
  },
  methods: {
    translateDelivery1() {
      for (let key of this.delivery_1) {
        key.title = this.$t("dicas_covid." + key.translate_key);
      }
    },
    translateDelivery2() {
      for (let key of this.delivery_2) {
        key.title = this.$t("dicas_covid." + key.translate_key);
      }
    },
    translateEstabelecimento() {
      for (let key of this.estabelecimento) {
        key.title = this.$t("dicas_covid." + key.translate_key);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-section {
  padding: 3rem 0;
}
a {
  color: #636e79;
}
a:hover {
  text-decoration: none;
}
</style>
