<template>
  <section :id="_id" class="space-top">
    <div class="text-center">
      <h2 v-if="title" class="pl-2 pr-2">
        {{ title }}
      </h2>
      <hr v-if="title" class="divider my-4" />

      <h5 class="text-left pl-3 pr-3">{{ subtitle }}</h5>
      <hr class="my-4" />

      <transition name="fade">
        <div class="list px-2">
          <div class="list__item" v-for="(item, index) in data" :key="index">
            <img
              class="list__item__image dica-img-card"
              :src="require(`../assets/icons/dicas/${item.img}`)"
              alt=""
            />
            <div class="list__item__content d-flex justify-content-center">
              <h3 class="list__item__content__title text-left">
                {{ item.title }}
              </h3>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    title: String,
    subtitle: String,
    _id: String
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.space-top {
  padding-top: 70px;
}

@media (max-width: 575px) {
  .dica-img-card {
    max-width: 50%;
  }
}
</style>
